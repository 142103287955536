<template>
  <div :class="`wrapper  wrapper--${bu} m-3 p-2`">
    <Loading v-if="loading" />
    <h2 class="text-center" v-if="!loading">Voorraad per inkoper</h2>
    <Tabs v-if="!loading">
      <Tab title="Totaal">
        <table :class="`w-100 table--perweek table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text table__head--md">Naam</th>
              <th class="table__head--text table__data--right">Aantal *</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Inkoop</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Kosten</th>
              <th class="table__head--text table__data--right">Stadagen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in total_per_buyer"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">{{ key }}</td>
              <td class="table__cell--default table__data--right">
                {{ item.aantal }}
              </td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default table__data--right">
                € {{ item.inkoop }}
              </td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default table__data--right">
                € {{ item.kosten }}
              </td>
              <td class="table__cell--default table__data--right">
                {{ item.stadagen }}
              </td>
            </tr>
          </tbody>
          <tfoot class="table__foot">
            <tr>
              <th class="table__foot--padding">Totaal:</th>
              <th class="table__foot--padding table__data--right">{{ total_stock_sum.aantal }}</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__foot--padding table__data--right">€ {{ total_stock_sum.inkoop }}</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__foot--padding table__data--right">€ {{ total_stock_sum.kosten }}</th>
              <th class="table__foot--padding table__data--right">{{ total_stock_sum.stadagen }}</th>
            </tr>
          </tfoot>
        </table>
        <span class="font-weight-bold m-2"
          >* Deze aantallen zijn inclusief de consignatie voertuigen</span
        >
      </Tab>
      <Tab
        v-for="(salesman, key) in stock_per_buyer"
        :key="key"
        :title="`${key}`"
      >
      <StockPerBuyerTable :table_data="salesman" :table_totals="stock_per_buyer_totals[key]" :table_style="bu" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import StockPerBuyerTable from "@/components/StockPerBuyerTable.vue"

export default {
  props: ["bu"],
  components: { Loading, Tabs, Tab, StockPerBuyerTable },
  data: () => ({
    stock_per_buyer: null,
    total_per_buyer: null,
    stock_per_buyer_totals: null,
    total_stock_sum: null,
    loading: true,
  }),
  created() {
    this.loading = true;
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      const stock_data_url = `stock-per-buyer/${bu}`;
      request(stock_data_url, "GET").then(
        ({ stock_per_buyer, stock_per_buyer_totals, total_stock }) => {
          const { total_per_buyer, total_stock_sum } = total_stock;
          console.log(stock_per_buyer)
          this.stock_per_buyer = stock_per_buyer;
          this.total_per_buyer = total_per_buyer;
          this.total_stock_sum = total_stock_sum;
          this.stock_per_buyer_totals = stock_per_buyer_totals;
          this.loading = false;
        }
      );
    },
    
  },
};
</script>
