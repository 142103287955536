<template>
 <table :class="`w-100 table--perweek table__border--${table_style}`">
          <thead :class="`table__head--${table_style}`">
            <tr>
              <th class="table__head--text">Foto</th>
              <th class="table__head--text">Item</th>
              <th v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text">Leverancier</th>
              <th class="table__head--text table__data--right">Stadagen</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Inkoop</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Kosten</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Vraagprijs</th>
              <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Consignatie</th>
              <th class="table__head--text table__data--right">Special</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in table_data"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">
                <img
                  :src="`https://apps.kleyn.com:1919/prod/public/data/mainimage/${item.nummer}/thumb`"
                />
              </td>
              <td class="table__cell--default">
                <ImageHover :itemnummer="item.nummer" :bu="table_style" />
                {{ item.soortnaam }}<br />
                {{ item.merknaam }}<br />
                {{ item.typenaam }}<br />
                {{ item.regjaar }}
              </td>
              <td v-if="checkroles(['lev_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__cell--default">
                <router-link
                  :to="{
                    name: 'leverancier',
                    params: { id: item.leverancier },
                  }"
                  >{{ item.leverancier }}</router-link><br />
                {{ item.levnaam }}<br />
                {{ item.levadres }}<br />
                {{ item.levstad }}<br />
                {{ item.levland }}
              </td>
              <td class="table__data--right table__cell--default">{{ item.stadagen }}</td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.inkoopbedrag }}</td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.kostenbedrag }}</td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.Vraagprijs }}</td>
              <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">{{ item.consignatie }}</td>
              <td class="table__data--right table__cell--default">{{ item.special }}</td>
            </tr>
          </tbody>
          <tfoot class="table__foot">
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding"></th>
            <th class="table__foot--padding table__data--right">{{table_totals.stadagen}}</th>
            <th class="table__foot--padding table__data--right">€ {{table_totals.inkoop}}</th>
            <th class="table__foot--padding table__data--right">€ {{table_totals.kosten}}</th>
            <th class="table__foot--padding table__data--right">€ {{table_totals.vraagprijs}}</th>
            <th class="table__foot--padding table__data--right"></th>
            <th class="table__foot--padding table__data--right"></th>
          </tfoot>
        </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
    props: {
        table_data: [Array, Object],
        table_totals: [Object, Array],
        table_style: [Number, String],
        
    },
    components: { ImageHover }
}

</script>